export const generateSelectedBookCodes = selectedContents => {
  const selectedBookCodes = []
  Object.values(selectedContents)
    .forEach(weeks => {
      Object.values(weeks)
        .forEach(days => {
          if (days.content_id.length > 0) {
            selectedBookCodes.push(days.content_id)
          }
        })
    })
  return selectedBookCodes
}

export const format = () => {
  // console.log('format')
}
