import _ from 'lodash'
import { getType, getDaysByPattern } from '@/libs/mk/product/week'

export const generateProductCode = (pattern, level) => {
  // Generate array: Ex: ['story', 'story', 'song']
  const dayCode = Object.values(pattern)
    .map(key => getType(key, level))

  // Group, then count, then reduce to a string: Ex: story_2_song_1
  const type = _.values(_.groupBy(dayCode))
    .map(d => ({ name: d[0], count: d.length }))
    .reduce((prevVal, currVal, idx) => (idx === 0 ? `${currVal.name}_${currVal.count}` : `${prevVal}_${currVal.name}_${currVal.count}`), '')

  return `${level}_${type}_${getDaysByPattern(pattern.sort())}`
}

export const format = () => {
  // console.log('format')
}
