import { getInitialType } from '@/libs/mk/product/week'

export const filterByLevelCodeAndType = (list, levelCode, type) => list.filter(item => item.id.includes(`ct_${levelCode}_${type}`))

export const formatContentList = obj => {
  let response = obj.contentItems.map(item => {
    if (obj.selectedBookCodes.indexOf(item.id) !== -1) {
      /* eslint no-underscore-dangle: 0 */
      /* eslint no-param-reassign: 0 */
      item._rowVariant = 'warning'
    } else {
      item._rowVariant = ''
    }
    return item
  })

  // Filter only if there's an active selection.
  if (obj.activeCalendarItem) {
    response = filterByLevelCodeAndType(response, obj.selectedLevelCode, getInitialType(obj.activeCalendarItem.day, obj.selectedLevelCode))
  }

  return response
}

export const parseContentListForSubmission = selectedContents => {
  const contentList = []

  let seq = 1
  selectedContents.forEach(values => {
    Object.values(values).forEach(item => {
      if (item.content_id.length > 0 && item.content_name.length > 0) {
        const { _inUse, _rowVariant, ...toUse } = item // Remove _inUse and _rowVariant and assign to toUse
        toUse.sequence = seq
        contentList.push(toUse)
        seq += 1
      }
    })
  })

  return contentList
}

export const format = () => {
  // console.log('format')
}
