import { findActiveLevelById } from '@/libs/mk/level'

export const getRotationOptions = (levels, selectedLevel) => {
  let max = 1
  const rotationOptions = []

  if (selectedLevel !== '') {
    max = findActiveLevelById(levels, selectedLevel).rotation
  }

  if (max <= 0) {
    max = 1 // TODO: Check if there's a better way ...
  }

  for (let x = 1; x <= max; x += 1) {
    rotationOptions[x] = {
      text: x,
      value: x,
    }
  }

  return rotationOptions
}

export const format = () => {
  // console.log('format')
}
