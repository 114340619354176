<template>
  <b-overlay
    :show="isContentSearching"
  >
    <br>
    <div style="max-height: 500px; overflow-y: scroll;">
      <b-table
        responsive
        :items="formattedContentItems"
        :fields="['selection', 'book_code', 'title']"
        class="mb-0"
      >
        <!-- Column: Selection -->
        <template #cell(selection)="data">
          <b-button
            variant="outline-primary"
            :disabled="data['item']._rowVariant === 'warning'"
            @click="selectProductTextbook(data['item'])"
          >
            {{ $t('selection') }}
          </b-button>
        </template>
        <!-- Column: Book Code -->
        <template #cell(book_code)="data">
          {{ data['item']['bookCode'] }}
        </template>
      </b-table>
    </div>
  </b-overlay>
  <!-- /.overlay -->
</template>

<script>
import {
  BOverlay,
  BTable,
  BButton,
} from 'bootstrap-vue'

import axios from '@/libs/axios'
import { formatContentList } from '@/libs/mk/product/content'

export default {
  name: 'Selection',
  components: {
    BOverlay,
    BTable,
    BButton,
  },
  props: {
    selectedCompany: {
      type: String,
      required: true,
    },
    levelCode: {
      type: String,
      required: true,
    },
    activeCalendarItem: {
      type: Object,
      default: null,
    },
    selectedBookCodes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isContentSearching: false,
      contentItems: [],
    }
  },
  computed: {
    formattedContentItems() {
      return formatContentList({
        contentItems: this.contentItems,
        selectedBookCodes: this.selectedBookCodes,
        selectedLevelCode: this.levelCode,
        activeCalendarItem: this.activeCalendarItem,
      })
    },
  },
  watch: {
    levelCode() {
      this.loadContentData()
    },
  },
  methods: {
    async loadContentData() {
      this.isContentSearching = true
      const response = await axios.post('admin/data-center/contents/list-by-filter', {
        company: `at_${this.selectedCompany}`,
        level: `levels_${this.levelCode.toLowerCase()}`,
      })
      this.contentItems = response.data.ehOnlineBooks
      this.isContentSearching = false
    },
    selectProductTextbook(book) {
      if (this.activeCalendarItem !== null) {
        this.$emit('textBookSelected', {
          contentId: book.id,
          contentCode: book.bookCode,
          contentTitle: book.title,
        })
      } else {
        this.$swal({
          title: 'Error',
          text: 'Select Item on Calendar First',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
