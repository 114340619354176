import _ from 'lodash'

export const weekObject = [
  {
    initial: 'M',
    full: 'Monday',
    l1_3: {
      tableName: 'R&S A',
      type: 'story',
      contentInitial: 'B',
    },
    l4_9: {
      tableName: 'R&S A',
      type: 'story',
      contentInitial: 'B',
    },
    l10: {
      tableName: 'TOEFL Listening',
      type: 'history',
      contentInitial: 'H',
    },
  },
  {
    initial: 'T',
    full: 'Tuesday',
    l1_3: {
      tableName: 'R&S B',
      type: 'story',
      contentInitial: 'L',
    },
    l4_9: {
      tableName: 'R&S B',
      type: 'story',
      contentInitial: 'L',
    },
    l10: {
      tableName: 'Science',
      type: 'science',
      contentInitial: 'C',
    },
  },
  {
    initial: 'W',
    full: 'Wednesday',
    l1_3: {
      tableName: 'Song',
      type: 'song',
      contentInitial: 'S',
    },
    l4_9: {
      tableName: 'R&S A',
      type: 'story',
      contentInitial: 'B',
    },
    l10: {
      tableName: 'Movie',
      type: 'movie',
      contentInitial: 'M',
    },
  },
  {
    initial: 'TH',
    full: 'Thursday',
    l1_3: {
      tableName: 'R&S B',
      type: 'story',
      contentInitial: 'L',
    },
    l4_9: {
      tableName: 'R&S B',
      type: 'story',
      contentInitial: 'L',
    },
    l10: {
      tableName: 'Book',
      type: 'book',
      contentInitial: 'K',
    },
  },
  {
    initial: 'F',
    full: 'Friday',
    l1_3: {
      tableName: 'R&S A',
      type: 'story',
      contentInitial: 'B',
    },
    l4_9: {
      tableName: 'R&S A',
      type: 'story',
      contentInitial: 'B',
    },
    l10: {
      tableName: 'TOEFL Writing',
      type: 'debate',
      contentInitial: 'D',
    },
  },
]

export const maxWeekCount = 4

export const weekInitials = weekObject.map(value => value.initial)

export const isFilledContentCanBeSubmitted = (filledContentCount, classPerWeek) => filledContentCount < classPerWeek * maxWeekCount

export const getDayOnWeekByDayAndLevel = (day, level) => {
  if (['L01', 'L02', 'L03'].indexOf(level) !== -1) {
    return weekObject[day].l1_3
  } if (['L04', 'L05', 'L06', 'L07', 'L08', 'L09'].indexOf(level) !== -1) {
    return weekObject[day].l4_9
  }
  return weekObject[day].l10
}

export const getInitialType = (day, level) => getDayOnWeekByDayAndLevel(day, level).contentInitial

export const getType = (day, level) => getDayOnWeekByDayAndLevel(day, level).type

export const getDaysByPattern = pattern => _.at(weekInitials, pattern).map(value => value.toLowerCase()).join('-')

export const getTableNames = level => weekObject.map((value, key) => ({
  fullName: value.full,
  tableName: getDayOnWeekByDayAndLevel(key, level).tableName,
}))

export const getWeekOptions = (pattern, perWeekCount) => weekObject.map((week, key) => ({
  value: key,
  text: week.full,
  disabled: pattern.length >= perWeekCount && pattern.indexOf(key) === -1,
}))

export const fillCalendarSelection = (pattern, level) => {
  const calendar = []

  for (let x = 0; x <= 3; x += 1) {
    calendar[x] = {}
    Object.keys(weekInitials)
      .map(key => parseInt(key, 10)).forEach(key => {
        calendar[x][key] = {
          id: '',
          content_id: '',
          content_name: '',
          week: x + 1,
          day: key,
          type: getDayOnWeekByDayAndLevel(key, level).type,
          _inUse: pattern.indexOf(key) !== -1,
          _rowVariant: '',
        }
      })
  }

  return calendar
}
